import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

function UnnaturalHumanity() {
  return (
    <>
      <div className="video-background" id="top-contribution">
        
        <div className="contribution-title">
          <div className="contribution-title-credits">
          <div className="credits-container">
            <span className="video-title">Unnatural Humanity&nbsp;</span>
            <span className="video-credits">by ReedFloorian & Lukr Range</span>
            </div>
            <a href="#moreinfos" className="video-more-infos">
          MORE INFO
        </a>
          </div>
          <div className="contribution-nav">
            <div className="nav-wrapper">
              <div>
              <Link className="nav-contributions" to="/golden-sound">
                NEXT
              </Link>

              <Link className="nav-contributions marginLeft" to="/last-hour">
                PREVIOUS
              </Link>

              <Link className="nav-contributions marginLeft" to="/">
                HOME
              </Link>
              </div>
            </div>
          </div>
        </div>

        <iframe
          src="https://player.vimeo.com/video/460839484?autoplay=true&loop=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
          width="100%"
          height="100%"
          frameBorder="0"
          controls={false}
          allow="autoplay"
          autoPlay={true}
          loop={true}
          title="Unnatural Humanity"
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </div>
      <div id="moreinfos" className="moreInfos unnaturalHumanity">
        <div className="left-container">
          <Fade>
            <p className="concept contribution-text">
              The Golden hour is the time when sunlight dies. This ending evokes
              apocalyptic thoughts on the contemporary world - social crises,
              over-exploitation of natural resources, and a global pandemia.
              Chaos reigns. Survival is necessary, as is perhaps extinction.
            </p>
          </Fade>
          <div className="backTop-contribution">
            <a className="backtoTop" href="#top-contribution">
              <img
                className="arrow-black"
                src="/uparrow.svg"
                alt="up arrow icon"
              />
            </a>
          </div>
        </div>

        <div className="right-container">
          

          <section className="img_detail_video">
            <Fade>
              <img
                src="/unnatural-humanity/unnaturalhumanity_1.jpg"
                alt="detail video"
              />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img
                src="/unnatural-humanity/unnaturalhumanity_2.jpg"
                alt="detail video"
              />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img
                src="/unnatural-humanity/unnaturalhumanity_3.jpg"
                alt="detail video"
              />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img
                src="/unnatural-humanity/unnaturalhumanity_4.jpg"
                alt="detail video"
              />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img
                src="/unnatural-humanity/unnaturalhumanity_5.jpg"
                alt="detail video"
              />
            </Fade>
          </section>
        </div>
      </div>
    </>
  );
}

export default UnnaturalHumanity;
