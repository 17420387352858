import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";

function About() {
  const [modalCredits, setModalCredits] = useState(false);
  const [titleOn, setTitleOn] = useState(false);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  const canvasRef2 = useRef(null);
  const contextRef2 = useRef(null);

  /* eslint-disable */

  useEffect(() => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    const dpi = window.devicePixelRatio;

    const canvas = canvasRef.current;
    // canvas.width = window.innerWidth * 2;
    // canvas.height = window.innerHeight * 2;
    //
    // canvas.style.minWidth = "100%";
    // canvas.style.minHeight = "100vh";

    canvas.width = w * dpi;
    canvas.height = h * dpi;
    canvas.style.width = w + "px";
    canvas.style.height = h + "px";

    canvas.style.minWidth = "100vw";
    canvas.style.minHeight = "100vh";

    const context = canvas.getContext("2d");
    context.scale(dpi, dpi);
    //context.scale(2, 2);
    context.lineCap = "round";

    context.fillStyle = "white";

    context.rect(0, 0, 10000, 10000);
    //context.rect(30, 20, 60, 40);
    context.fill();

    // context.shadowBlur = 50;
    // context.shadowColor = "black";

    context.strokeStyle = "black";
    context.lineWidth = 300;

    context.lineCap = "round";
    context.lineJoin = "round";

    contextRef.current = context;
    contextRef.current.beginPath();

    const canvas2 = canvasRef2.current;
    // canvas2.width = window.innerWidth * 2;
    // canvas2.height = window.innerHeight * 2;
    //
    // canvas2.style.minWidth = "100%";
    // canvas2.style.minHeight = "100vh";

    canvas2.width = w * dpi;
    canvas2.height = h * dpi;
    canvas2.style.width = w + "px";
    canvas2.style.height = h + "px";

    canvas2.style.minWidth = "100vw";
    canvas2.style.minHeight = "100vh";

    const context2 = canvas2.getContext("2d");
    //context2.scale(2, 2);
    context2.scale(dpi, dpi);
    context2.lineCap = "round";

    context2.fillStyle = "black";

    context2.rect(0, 0, 10000, 10000);
    //context.rect(30, 20, 60, 40);
    context2.fill();

    // context2.shadowBlur = 50;
    // context.shadowColor = "white";

    context2.strokeStyle = "white";
    context2.lineWidth = 250;

    context2.lineCap = "round";
    context2.lineJoin = "round";

    contextRef2.current = context2;
    contextRef2.current.beginPath();

    console.log(contextRef.current, contextRef2.current);
  }, []);

  const draw = ({ nativeEvent }) => {
    const { offsetX, offsetY, touches } = nativeEvent;
    //const radius = 10;

    //contextRef.current.moveTo(offsetX, offsetY);

    //contextRef.current.closePath();

    let x;
    let y;

    if (touches) {
      if (touches[0]) {
        x = touches[0].clientX;
        y = touches[0].clientY;
      }
    } else {
      x = offsetX;
      y = offsetY;
    }

    //draw line

    contextRef.current.lineTo(x, y);
    contextRef.current.stroke();

    contextRef2.current.lineTo(x, y);
    contextRef2.current.stroke();

    // contextRef.current.beginPath();
    // contextRef.current.moveTo(offsetX + radius, offsetY);
    // contextRef.current.arc(offsetX, offsetY, radius, 0, Math.PI * 2);
    //
    // contextRef.current.fill();
  };

  useEffect(() => {
    setTimeout(() => {
      setTitleOn(true);
    }, 700);
  }, []);

  const animationModal = useSpring({
    opacity: modalCredits ? 1 : 0,
    display: modalCredits ? "flex" : "none"
  });

  const animationTitle = useSpring({
    opacity: titleOn ? 1 : 0
  });

  return (
    <>
      <animated.div id="credits" style={animationModal}>
        <div
          className="close-credits"
          onClick={() => setModalCredits(!modalCredits)}
        >
          X
        </div>
        <div className="credits-text-container">
          <p className="credits-text">
            CREDITS: <br />
            videos & sound contributors: <br />
            Alice Brazzit - Audrey Kadjar - Lukr Range - PAULINE MAURE - Señora - Monica Montanari - Refracted - ReedFloorian
            <br />
            <br />
            event contributors (party in Berlin - date TBA): <br />
            event poster design: Devid Sita <br />
            set design: Paulina Radic <br />
            light sculpture: Sandra Santiago <br />
            painting: Alessandro Gerull <br />
            <br />
            performance: Ilaria Bassi Vergani & Monica Montanari
            <br />
            music sets by techno label Diffuse Reality - program TBA
            <br />
            <br />
            the golden hour - concept: Monica Montanari  & Audrey Kadjar
            <br />
            the golden hour - sound design: Monica Montanari <br />
            ABOUT HOURS - logo design: Camila Ayala <br />
            ABOUT HOURS - founder & code: Audrey Kadjar
            Kadjar <br />
          </p>
        </div>
      </animated.div>

      <section
        className="canvasContainer"
        onMouseMove={draw}
        onTouchMove={draw}
      >
        <div>
          <div className="scratch-layer">
            <span className="scratch-around"> scratch around </span>

            <animated.div style={animationTitle} className="title-about">
              <span className="syntheticvelvet_intro">ABOUT HOURS</span>
              <span className="slogan">issue iii - the golden hour</span>
            </animated.div>
          </div>
          <canvas className="in" ref={canvasRef2} />
        </div>
        <section className="content">
          <div className="info">
            <div className="text-container">
              <p className="text-info">
                The Golden hour is the last hour of sunlight in the day. The
                most fluid time of all, its length depends on the location and
                the time of the year. <br />
                <br />
                The Golden hour is popular: it gets its fame from the special
                quality of its light, being photographers’ favorite time to
                shoot. A search of the term on Google accounts for more than
                1.140.000.000 results, with countless pages describing its
                unique, reddish light.
                <br /> <br />
                But this transitive time is also crucial, powerful. In medical
                terms, "Golden hour" refers to the first hour after a traumatic
                injury, when emergency treatment is most likely to be
                successful. A shifting point.
                <br />
                <br />
                For its third issue, ABOUT HOURS explores the Golden hour.
                Artists contributed with exclusive works spanning video, sound,
                performance, sculpture, and painting. Featuring the media works,
                this digital issue was conceived as a complementary half of the
                launch party - an art and music event that was postponed due to
                the COVID-19 outbreak (TBA). <br /> <br /> A celebration of time
                through code, this digital object brings the works together,
                imagining what the concept of Golden hour means today - from
                death to transformation.
                <br /> <br />
                <span className="quote">
                  ABOUT HOURS is a Berlin-based digital project and magazine
                  imaginatively exploring the concept of time. It focuses on one specific hour
                  per issue and builds a different website for each edition.
                  Check out the previous issues: &nbsp;
                  <a
                    className="link-intro"
                    href="https://www.abouthours-threeten.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    3:10 PM
                  </a>
                  &nbsp; and &nbsp;
                  <a
                    className="link-intro"
                    href="https://www.abouthours-fiveforty.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    5:40 AM
                  </a>
                  {/* &nbsp; */}
                  {/* <a
                    className="link-intro"
                    href="https://www.instagram.com/syntheticvelvet/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;Follow on Instagram
                  </a> */}
                </span>
              </p>
            </div>

            <Link to="/last-hour" className="issue-info corner">
              start here
            </Link>

            <a
              href="https://www.google.com/search?q=golden+hour&tbm=isch&ved=2ahUKEwjDxY39-qfsAhUG5hoKHTEDAmsQ2-cCegQIABAA&oq=golden+hour&gs_lcp=CgNpbWcQA1AAWABghytoAHAAeACAAQCIAQCSAQCYAQCqAQtnd3Mtd2l6LWltZw&sclient=img&ei=05OAX4OQFIbMa7GGiNgG&bih=717&biw=1440"
              target="_blank"
              rel="noopener noreferrer"
              className="credits corner"
            >
              golden hour
            </a>

            <span
              className="gh-info corner"
              onClick={() => setModalCredits(!modalCredits)}
            >
              credits
            </span>

            <span className="start corner">issue III</span>
          </div>

          <canvas className="out" ref={canvasRef} />
        </section>
      </section>
    </>
  );
}

export default About;
