import React, { useEffect, useState, useRef } from "react";
import goldensound from "./assets/contributions/goldensound.mp3";
import Loading from "./Loading.js";
import { Link } from "react-router-dom";

function CanvasGoldenSound() {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  const canvasRef2 = useRef(null);
  const contextRef2 = useRef(null);

  const [isPlaying, setisPlaying] = useState(false);
  const [playStop, setPlayStop] = useState("sound on");
  const [loading, setLoading] = useState(true);

  /* eslint-disable */

  useEffect(() => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    const dpi = window.devicePixelRatio;

    const canvas = canvasRef.current;

    // canvas.width = window.innerWidth * 2;
    // canvas.height = window.innerHeight * 2;
    //
    // canvas.style.minWidth = "100%";
    // canvas.style.minHeight = "100vh";

    canvas.width = w * dpi;
    canvas.height = h * dpi;
    canvas.style.width = w + "px";
    canvas.style.height = h + "px";

    canvas.style.minWidth = "100vw";
    canvas.style.minHeight = "100vh";

    const context = canvas.getContext("2d");
    context.scale(dpi, dpi);

    context.lineCap = "round";

    context.fillStyle = "white";

    context.rect(0, 0, 10000, 10000);
    //context.rect(30, 20, 60, 40);
    context.fill();

    // context.shadowColor = "black";

    context.strokeStyle = "black";
    context.lineWidth = 14.5;

    context.lineCap = "round";
    context.lineJoin = "round";

    contextRef.current = context;
    contextRef.current.beginPath();

    const canvas2 = canvasRef2.current;
    // canvas2.width = window.innerWidth * 2;
    // canvas2.height = window.innerHeight * 2;
    //
    // canvas2.style.minWidth = "100%";
    // canvas2.style.minHeight = "100vh";

    canvas2.width = w * dpi;
    canvas2.height = h * dpi;
    canvas2.style.width = w + "px";
    canvas2.style.height = h + "px";

    // canvas2.width = window.innerWidth * 2;
    // canvas2.height = window.innerHeight * 2;

    canvas2.style.minWidth = "100vw";
    canvas2.style.minHeight = "100vh";

    const context2 = canvas2.getContext("2d");
    //context2.scale(2, 2);
    context2.scale(dpi, dpi);
    context2.lineCap = "round";

    context2.fillStyle = "black";

    context2.rect(0, 0, 10000, 10000);
    //context.rect(30, 20, 60, 40);
    context2.fill();

    // context2.shadowBlur = 5;
    // context.shadowColor = "white";

    context2.strokeStyle = "white";
    context2.lineWidth = 2;

    context2.lineCap = "round";
    context2.lineJoin = "round";

    contextRef2.current = context2;
    contextRef2.current.beginPath();

    console.log(contextRef.current, contextRef2.current);
  }, []);

  const draw = ({ nativeEvent }) => {
    const { offsetX, offsetY, touches } = nativeEvent;
    //const radius = 10;

    //contextRef.current.moveTo(offsetX, offsetY);

    //contextRef.current.closePath();

    let x;
    let y;

    if (touches) {
      if (touches[0]) {
        x = touches[0].clientX;
        y = touches[0].clientY;
      }
    } else {
      x = offsetX;
      y = offsetY;
    }

    //draw line

    contextRef.current.lineTo(x, y);
    contextRef.current.stroke();
    contextRef.current.closePath();

    contextRef2.current.lineTo(x, y);
    contextRef2.current.stroke();
    contextRef2.current.closePath();

    // contextRef.current.beginPath();
    // contextRef.current.moveTo(offsetX + radius, offsetY);
    // contextRef.current.arc(offsetX, offsetY, radius, 0, Math.PI * 2);
    //
    // contextRef.current.fill();
  };

  const toggleSound = () => {
    console.log("audio on");
    const audio = document.querySelector("audio");
    audio.load();

    if (isPlaying) {
      audio.pause();
      setisPlaying(false);
      setPlayStop("sound on");
    } else {
      audio.play();
      setisPlaying(true);
      setPlayStop("sound off");
    }
  };

  const resetCanvas = () => {
    window.location.reload(false);
  };

  /* eslint-disable */
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  return (
    <>
      {loading && (
        <div className="loading-container-goldenSound">
          <div className="load">
            <span className="loading-text"> loading... </span>
            <Loading />
          </div>
        </div>
      )}

      <section
        className="canvasContainer"
        onMouseMove={draw}
        onTouchMove={draw}
      >
        <div className="contribution-title-goldenSound">
          <div className="golden-sound-titles">
            <div className="contribution-title-credits">
              <span className="video-title">
                "how does the Golden Hour sound?"&nbsp;
              </span>
              <span className="video-credits"> by Refracted</span>
            </div>

            <div className="goldenSound-wrapper">
              <div
                className={
                  isPlaying
                    ? "goldenSound-sound goldensound-black"
                    : "goldenSound-sound goldensound-grey"
                }
              >
                <span className="more-infos-goldensound" onClick={toggleSound}>
                  {playStop}
                </span>
              </div>

              <div className="goldenSound-sound goldensound-grey marginLeft">
                <span
                  className="more-infos-goldensound"
                  onClick={resetCanvas}
                  onTouchStart={resetCanvas}
                >
                  reset canvas
                </span>
              </div>
            </div>
          </div>

          <div className="contribution-nav">
            <div className="nav-wrapper">
              <div>
              <Link
                className="nav-contributions goldenSoundNav"
                to="/aquamorphosis"
              >
                NEXT
              </Link>

              <Link
                className="nav-contributions marginLeft goldenSoundNav"
                to="/unnatural-humanity"
              >
                PREVIOUS
              </Link>

              <Link
                className="nav-contributions marginLeft goldenSoundNav"
                to="/"
              >
                HOME
              </Link>
              </div>
            </div>
          </div>
        </div>

        <section className="canvas-container">
          <div>
            <div className="scratch-layer-goldenSound"></div>
            <canvas
              className="in"
              onMouseMove={draw}
              onTouchMove={draw}
              ref={canvasRef2}
            ></canvas>
          </div>
          <section className="content">
            <div className="goldenSound-background">
              <audio>
                <source src={goldensound} type="audio/mp3" />
                Your browser does not support the audio tag.
              </audio>
            </div>
            <canvas
              className="out"
              ref={canvasRef}
              onMouseMove={draw}
              onTouchMove={draw}
            />
          </section>
        </section>
      </section>
    </>
  );
}

export default CanvasGoldenSound;
