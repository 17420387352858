import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

function Aquamorphosis() {
  return (
    <>
      <div className="video-background" id="top-contribution">
        
        <div className="contribution-title">
          <div className="contribution-title-credits">
            <div className="credits-container">
            <span className="video-title">Aquamorphosis &nbsp;</span>
            <span className="video-credits">
              by Audrey Kadjar & Monica Montanari
            </span>
            </div>
            <a href="#moreinfos" className="video-more-infos">
          MORE INFO
        </a>
          </div>
          <div className="contribution-nav">
            <div className="nav-wrapper">
              <div>
              <Link className="nav-contributions" to="/before">
                NEXT
              </Link>

              <Link className="nav-contributions marginLeft" to="/golden-sound">
                PREVIOUS
              </Link>

              <Link className="nav-contributions marginLeft" to="/">
                HOME
              </Link>
              </div>
            </div>
          </div>
        </div>
        <iframe
          src="https://player.vimeo.com/video/402875864?autoplay=1&loop=1&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
          width="100%"
          height="100%"
          frameBorder="0"
          controls={false}
          allow="autoplay"
          autoPlay={true}
          loop={true}
          title="Aquamorphosis"
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </div>
      <div id="moreinfos" className="moreInfos aquamorphosis">
        <div className="left-container">
          <p className="concept contribution-text">
            <Fade>
              Every day, the night transforms into day, the day transforms into
              night. Taking inspiration from the Golden hour’s cyclical and
              transient nature, the video explores the concept of metamorphosis
              with a focus on cosmic and organic phenomena.
              <p className="poetry-aqua">
                I observe the day flowing over <br /> liquid movements merge
                <br />
                into cosmic oscillations <br />
                the real become diffuse, <br /> fixed identities fade, <br />
                and metamorphosis begin.
              </p>
            </Fade>
          </p>

          <div className="backTop-contribution">
            <a className="backtoTop" href="#top-contribution">
              <img
                className="arrow-black"
                src="/uparrow.svg"
                alt="up arrow icon"
              />
            </a>
          </div>
        </div>

        <div className="right-container">


          <section className="img_detail_video">
            <Fade>
              <img src="/aquamorphosis/aquamorphosis5.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/aquamorphosis/aquamorphosis6.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/aquamorphosis/aquamorphosis2.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/aquamorphosis/aquamorphosis9.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/aquamorphosis/aquamorphosis8.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/aquamorphosis/aquamorphosis1.jpg" alt="detail video" />
            </Fade>
          </section>
        </div>
      </div>
    </>
  );
}

export default Aquamorphosis;
