import {
  TextureLoader,
  WebGLRenderTarget,
  Object3D,
  LinearFilter
} from "three";
import React, { Suspense, useMemo, useRef, useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import { Canvas, useLoader, useThree, useFrame } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import BackfaceMaterial from "./backface-material";
import RefractionMaterial from "./refraction-material";
import diamondUrl from "./assets/diamond.glb";
import textureUrl from "./assets/sky4_edited.png";
import "./App.css";
import Captcha from "./Captcha.js";
//import { TransformControls, StandardEffects } from "drei";
// import { useGesture } from "react-use-gesture";
// import { useSpring, a } from "@react-spring/three";
// import { DragControls } from "three/examples/jsm/controls/DragControls";
// import Info from "./Info.js";
import Loading from "./Loading.js";

function Background() {
  const { viewport, aspect } = useThree();
  //const transform = useRef();
  const texture = useLoader(TextureLoader, textureUrl);
  useMemo(() => (texture.minFilter = LinearFilter), [texture.minFilter]);
  // Calculates a plane filling the screen similar to background-size: cover
  const adaptedHeight =
    3800 *
    (aspect > 5000 / 3800 ? viewport.width / 5000 : viewport.height / 3800);
  const adaptedWidth =
    5000 *
    (aspect > 5000 / 3800 ? viewport.width / 5000 : viewport.height / 3800);
  return (
    <mesh layers={1} scale={[adaptedWidth, adaptedHeight, 1]}>
      <planeBufferGeometry attach="geometry" />
      <meshBasicMaterial attach="material" map={texture} depthTest={false} />
    </mesh>
  );
}

function Diamonds() {
  const { size, viewport, gl, scene, camera, clock } = useThree();
  //const aspect = size.width / viewport.width;
  const model = useRef();
  const gltf = useLoader(GLTFLoader, diamondUrl);
  //const transform = useRef();

  // Create Fbo's and materials
  const [
    envFbo,
    backfaceFbo,
    backfaceMaterial,
    refractionMaterial
  ] = useMemo(() => {
    const envFbo = new WebGLRenderTarget(size.width, size.height);
    const backfaceFbo = new WebGLRenderTarget(size.width, size.height);
    const backfaceMaterial = new BackfaceMaterial();
    const refractionMaterial = new RefractionMaterial({
      envMap: envFbo.texture,
      backfaceMap: backfaceFbo.texture,
      resolution: [size.width, size.height]
    });
    return [envFbo, backfaceFbo, backfaceMaterial, refractionMaterial];
  }, [size]);

  // Create random position data
  const dummy = useMemo(() => new Object3D(), []);
  const diamonds = useMemo(
    () =>
      new Array(30).fill().map((_, i) => ({
        position: [
          i < 5 ? 0 : viewport.width / 2 - Math.random() * viewport.width,
          40 - Math.random() * 40,
          i < 5 ? 26 : 10 - Math.random() * 20
        ],
        factor: 0.1 + Math.random(),
        direction: Math.random() < 0.5 ? -1 : 1,
        rotation: [
          Math.sin(Math.random()) * Math.PI,
          Math.sin(Math.random()) * Math.PI,
          Math.cos(Math.random()) * Math.PI
        ]
      })),
    [viewport.width]
  );

  useFrame(
    ({ gl }) =>
      void ((gl.autoClear = false), gl.clearDepth(), gl.render(scene, camera)),
    10
  );

  // Render-loop
  useFrame(() => {
    // Update instanced diamonds
    diamonds.forEach((data, i) => {
      const t = clock.getElapsedTime();
      data.position[1] -= (data.factor / 5) * data.direction;
      if (data.direction === 1 ? data.position[1] < -50 : data.position[1] > 50)
        data.position = [
          i < 5 ? 0 : viewport.width / 2 - Math.random() * viewport.width,
          50 * data.direction,
          data.position[2]
        ];
      const { position, rotation, factor } = data;
      dummy.position.set(position[0], position[1], position[2]);
      dummy.rotation.set(
        rotation[0] + t * factor,
        rotation[1] + t * factor,
        rotation[2] + t * factor
      );
      dummy.scale.set(1 + factor, 1 + factor, 1 + factor);
      dummy.updateMatrix();
      model.current.setMatrixAt(i, dummy.matrix);
    });
    model.current.instanceMatrix.needsUpdate = true;
    // Render env to fbo
    gl.autoClear = false;
    camera.layers.set(1);
    gl.setRenderTarget(envFbo);
    gl.render(scene, camera);
    // Render cube backfaces to fbo
    camera.layers.set(0);
    model.current.material = backfaceMaterial;
    gl.setRenderTarget(backfaceFbo);
    gl.clearDepth();
    gl.render(scene, camera);
    // Render env to screen
    camera.layers.set(1);
    gl.setRenderTarget(null);
    gl.render(scene, camera);
    gl.clearDepth();
    // Render cube with refraction material to screen
    camera.layers.set(0);
    model.current.material = refractionMaterial;
    gl.render(scene, camera);
  }, 1);

  return (
    <instancedMesh ref={model} args={[null, null, diamonds.length]}>
      <bufferGeometry
        dispose={false}
        attach="geometry"
        {...gltf.__$[1].geometry}
      />
      <meshBasicMaterial attach="material" />
    </instancedMesh>
  );
}

function AnimationHome({
  captchaVerified,
  captchaVisible,
  setCaptchaVisible,
  makeCaptchaVisible
}) {
  const [loading, setLoading] = useState(true);

  /* eslint-disable */
  useEffect(() => {
    //     audio.addEventListener('timeupdate', function(){
    //     var buffer = .44
    //     if(audio.currentTime > audio.duration - buffer){
    //         audio.currentTime = 0
    //         console.log("play")
    //         audio.play()
    //     }
    // });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // useEffect(() => {
  //   const audio = document.querySelector("audio");
  //   audio.load();
  //   audio.loop = true;
  //   audio.play()
  // }, [])

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="load">
            <span className="loading-text"> loading... </span>
            <Loading />
          </div>
        </div>
      )}

      <div className="logo_images-home">
        <img className="syntheticvelvet-logo" src="logo-sv.svg" alt="logo" />
        <img className="syntheticvelvet-logo" src="logo-sv2.svg" alt="logo" />
        <img className="syntheticvelvet-logo" src="logo-sv3.svg" alt="logo" />
      </div>

      <Captcha captchaVerified={captchaVerified} />

      <Canvas camera={{ fov: 50, position: [0, 0, 30] }}>
        <Suspense fallback={null}>
          <Background />
          <Diamonds />
        </Suspense>
      </Canvas>
    </>
  );
}

export default AnimationHome;

//  <TransformControls ref={transform}>
//  </TransformControls>
//  <span onClick={toggleSound} className="sound-home"> sound  {playStop}</span>

//
