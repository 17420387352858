import React, { useState, useEffect } from "react";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import LoopIcon from "@material-ui/icons/Loop";
//import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
//import RotateLeftIcon from "@material-ui/icons/RotateLeft";
//import homeSound from "./assets/Risveglio_Snippet.ogg";
import homeSound from "./assets/Risveglio_Snippet.mp3";
import { animated, useSpring } from "react-spring";

function Captcha({ captchaVerified }) {
  // const images1 = [
  //   { link: "/captcha/captcha1.png", index: 7 },
  //   { link: "/captcha/captcha2-gh.png", index: 1 },
  //   { link: "/captcha/captcha3.png", index: 2 },
  //   { link: "/captcha/captcha4.png", index: 3 },
  //   { link: "/captcha/captcha5.png", index: 4 },
  //   { link: "/captcha/captcha6-gh.png", index: 5 },
  //   { link: "/captcha/captcha7-gh.png", index: 8 },
  //   { link: "/captcha/captcha8.png", index: 6 },
  //   { link: "/captcha/captcha9.png", index: 9 }
  // ];

  const images2 = [
    { link: "/captcha-2/captcha_variation2_1-gh.png", index: 10 },
    { link: "/captcha-2/captcha_variation2_6.png", index: 15 },
    { link: "/captcha-2/captcha_variation2_2-gh.png", index: 13 },
    { link: "/captcha-2/captcha_variation2_5.png", index: 12 },
    { link: "/captcha-2/captcha_variation2_7.png", index: 16 },
    { link: "/captcha-2/captcha3.png", index: 11 },
    { link: "/captcha-2/captcha_variation2_4-edited-gh.png", index: 14 },
    { link: "/captcha-2/captcha_variation2_8.png", index: 17 },
    { link: "/captcha-2/captcha_variation2_9.png", index: 18 }
  ];

  const [ghCount, setGhCount] = useState(0);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  //const [images, setImages] = useState(false);
  const [isPlaying, setisPlaying] = useState(false);
  //const [help, setHelp] = useState(false);

  const [captcha, setCaptcha] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    setTimeout(() => {
      setCaptcha(true);
    }, 1300);
  }, []);

  /* eslint-disable */
  // useEffect(() => {
  //   const num = Math.floor(Math.random() * 2);
  //   console.log("num", num);
  //   if (num === 1) {
  //     setImages(images1);
  //     console.log(images);
  //   } else {
  //     setImages(images2);
  //     console.log(images);
  //   }
  // }, []);

  const animation = useSpring({
    opacity: captcha ? 0.95 : 0
  });

  const toggleSound = () => {
    console.log("audio on");
    const audio = document.querySelector("audio");
    audio.load();

    if (isPlaying) {
      audio.pause();
      setisPlaying(false);
    } else {
      audio.play();
      setisPlaying(true);
    }
  };

  //import all images
  //x4 create arrays with images for variations
  //at each loading: choose randomly a variation

  const captchaSelect = e => {
    if (error) setError(false);
    //if(help) setHelp(false)

    if (e.target.style.opacity === "0.3") {
      e.target.style.opacity = "1";

      if (e.target.src.includes("gh")) {
        setGhCount(prevState => prevState - 1);
      } else {
        setCount(prevState => prevState - 1);
      }
    } else {
      e.target.style.opacity = "0.3";

      if (e.target.src.includes("gh")) {
        setGhCount(prevState => prevState + 1);
      } else {
        setCount(prevState => prevState + 1);
      }
    }
  };

  const checkCaptcha = () => {
    if (ghCount === 0 && count === 0) {
      setError("Please select images by clicking on them.");
    } else if (ghCount === 3 && count === 0) {
      setLoading(true);
      captchaVerified();
    } else {
      setError("Your selection is not correct - try again!");
    }
  };

  const reset = () => {
    if (loading) return;
    if (error) setError(false);
    //if(help) setHelp(false)
    if (count) setCount(0);
    if (ghCount) setGhCount(0);

    const images = document.querySelectorAll("img");

    for (let i = 0; i < images.length; i++) {
      images[i].style.opacity = "1";
    }
  };


  // const showHelp = () => {
  //   setHelp("The golden hour is last hour of sunlight in the day.");
  // }

  return (
    <>
      <animated.div className="captcha-container" style={animation}>
        <div className="captcha-select-wrapper">
          To enter, select all images with a&nbsp;
          <div className="sound-captcha-container">
            <span className="bold"> 
            <a className="link-captcha" rel="noopener noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/Golden_hour">golden hour  </a>
            </span>
            {isPlaying ? (
              <VolumeOffIcon
                className="volumeIcon"
                style={{ fill: "white" }}
                onClick={toggleSound}
              />
            ) : (
              <VolumeUpIcon
                onClick={toggleSound}
                className="volumeIcon"
                style={{ fill: "white" }}
              />
            )}
          </div>
        </div>
        <div className="captcha-images" onClick={captchaSelect}>
          {images2.map(img => (
            <img key={img.index} src={img.link} alt="captcha" />
          ))}
        </div>
       

        <div className="captchaButtonsContainer">
          <div className="syntheticvelvet-name">
          {error && <div className="error">{error}</div>}
          </div>

          

          <div className="captcha-verify">
            <LoopIcon
              className={loading ? "loadingCaptcha spin" : "loadingCaptcha"}
              alt="captcha"
              onClick={reset}
              style={{ fill: "grey" }}
              fontSize="medium"
            />
            <button className="captcha" onClick={checkCaptcha}>
              VERIFY
            </button>
          </div>
        </div>
      </animated.div>

      <audio loop>
        <source src={homeSound} type="audio/mp3" />
        Your browser does not support the audio tag.
      </audio>
    </>
  );
}

export default Captcha;

// <span className="defintion">
//   i.e: the period of the day just before the sun sets or after it rises,
//   when the light is redder and softer than usual
// </span>


// {help && <div className="help">{help}</div>}

//<span className="sv" onClick={showHelp}> help </span>