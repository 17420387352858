import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

function LastHour() {
  return (
    <>
      <div className="video-background lastHour" id="top-contribution">
        
        <div className="contribution-title">
          <div className="contribution-title-credits">
          <div className="credits-container">
            <span className="video-title">
              Last hour on the last island&nbsp;
            </span>
            <span className="video-credits">by PAULINE MAURE</span>
            </div>
            <a href="#moreinfos" className="video-more-infos"> MORE INFO </a>
          </div>

          <div className="contribution-nav">
            <div className="nav-wrapper">
              <div>
              <Link className="nav-contributions" to="/unnatural-humanity">
                NEXT
              </Link>

              <Link className="nav-contributions marginLeft" to="/info">
                PREVIOUS
              </Link>

              <Link className="nav-contributions  marginLeft" to="/">
                HOME
              </Link>
              </div>

              
            </div>
          </div>
        </div>
        <iframe
          src="https://player.vimeo.com/video/455255880?autoplay=1&loop=1&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
          width="100%"
          height="100%"
          frameBorder="0"
          controls={false}
          allow="autoplay"
          autoPlay={true}
          loop={true}
          title="Last hour on the last island"
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </div>
      <div className="moreInfos lastHour" id="moreinfos">
        <Fade>
          <div className="left-container">
            <p className="concept contribution-text">
              The last hour can be both the warmest and most photogenic time of
              the day as well as the time before imminent death. Both
              photography and funerary rituals share the desire to immortalize
              what is living and meant to die. <br /> <br />
              On the last island, at the last hour, a visual sorcery is
              starting. It attempts to transmute a face, to escape its decaying
              nature. How does one eternize flesh, how does one metalize the
              organic?
            </p>
            <div className="backTop-contribution">
            <a className="backtoTop" href="#top-contribution">
              <img
                className="arrow-black"
                src="/uparrow.svg"
                alt="up arrow icon"
              />
            </a>
          </div>
          </div>
        </Fade>
        <div className="right-container">
         

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_4.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_5.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_9.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_10.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_3.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_6.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_7.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_8.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_11.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/last-hour/lasthour_1.jpg" alt="detail video" />
            </Fade>
          </section>
        </div>
      </div>
    </>
  );
}

export default LastHour;
