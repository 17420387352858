import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

function LastBreath() {
  return (
    <>
      <div className="video-background" id="top-contribution">
       
        <div className="contribution-title">
          <div className="contribution-title-credits">
            <div className="credits-container">
            <span className="video-title">Before&nbsp;</span>
            <span className="video-credits">by ALICE BRAZZIT & SEÑORA</span>
            </div>
            <a href="#moreinfos" className="video-more-infos">
          MORE INFO
        </a>
          </div>
          <div className="contribution-nav">
            <div className="nav-wrapper">
              <div>
              <Link className="nav-contributions" to="/last-hour">
                NEXT
              </Link>

              <Link
                className="nav-contributions marginLeft"
                to="/aquamorphosis"
              >
                PREVIOUS
              </Link>

              <Link className="nav-contributions marginLeft" to="/">
                HOME
              </Link>
              </div>
            </div>
          </div>
        </div>
        <iframe
          src="https://player.vimeo.com/video/457016933?autoplay=1&loop=1&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
          width="100%"
          height="100%"
          frameBorder="0"
          controls={false}
          allow="autoplay"
          autoPlay={true}
          loop={true}
          title="Before"
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </div>
      <div id="moreinfos" className="moreInfos lastBreath">
        <div className="right-container">
         

          <section className="img_detail_video">
            <Fade>
              <img src="/before/before8.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/before/before5.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/before/before9.png" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/before/before1.jpg" alt="detail video" />
            </Fade>
          </section>

          <section className="img_detail_video">
            <Fade>
              <img src="/before/before6.jpg" alt="detail video" />
            </Fade>
          </section>
        </div>

        <div className="left-container">
          <p className="concept contribution-text">
            <Fade>
              Just as the Golden hour marks the end of a cosmic cycle, "Before"
              reflects on the human life cycle by weaving together memories of
              the personal and the collective.
            </Fade>
          </p>
          <div className="backTop-contribution">
            <a className="backtoTop" href="#top-contribution">
              <img
                className="arrow-black"
                src="/uparrow.svg"
                alt="up arrow icon"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default LastBreath;
