import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import AnimationHome from "./AnimationHome.js";
import LastHour from "./LastHour.js";
import UnnaturalHumanity from "./UnnaturalHumanity.js";
import LastBreath from "./LastBreath.js";
import Aquamorphosis from "./Aquamorphosis.js";
import About from "./About.js";
//import CanvasDraw from "./CanvasDraw.js";
import CanvasGoldenSound from "./CanvasGoldenSound.js";

function App() {
  const history = useHistory();

  const captchaVerified = () => {
    setTimeout(() => {
      history.push("/info");
    }, 1000);
  };

  return (
    <>
      <Switch>
        <Route path="/info">
          <About />
        </Route>

        <Route exact path="/">
          <AnimationHome captchaVerified={captchaVerified} />
        </Route>

        <Route exact path="/last-hour">
          <LastHour />
        </Route>
        <Route exact path="/unnatural-humanity">
          <UnnaturalHumanity />
        </Route>
        <Route exact path="/before">
          <LastBreath />
        </Route>
        <Route exact path="/aquamorphosis">
          <Aquamorphosis />
        </Route>
        <Route exact path="/golden-sound">
          <CanvasGoldenSound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
